/* eslint-disable indent */
import {get} from 'lodash';
import {action, observable} from 'mobx';
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {Boundaries} from '@techsee/techsee-ui-common/lib/draggble-panel/contracts';
import {Channel} from '../../services/AngularServices/WifiAnalyzerService';
import {getRootStore} from '../../app.bootstrap';

export interface IWifiAnalyzerResults {
    id: string;
    name: string;
    description: string;
    createdOn: Date;
    accountId: string;
    roomId: string;
    platformType: string;
    active?: boolean;
    wifiConnectionInfo: any;
    scanAreaResults: any[];
    networkDiscoveryDevices: any[];
    networkSpeed: any;
    channelAnalysis: Channel[];
}

export interface IWifiAnalyzerResultsHistory {
    previews: IWifiAnalyzerResults[];
    totalResults: number;
}

export interface IWifiAnalyzerController {
    readonly isVisible: boolean;
    readonly results: IWifiAnalyzerResults | null;

    readonly position: {x: number; y: number};
    readonly boundaries: Boundaries;
    readonly offset: Boundaries;
    readonly deviceCategory: DeviceCategory;
    readonly currentTab: number;
    readonly isMinimized: boolean;
    readonly isScanning: boolean;
    readonly channelAnalysis: Channel[];

    show(): void;
    hide(): void;
    setResults(results: IWifiAnalyzerResults): void;
    minimize(): void;
    maximize(): void;
    getFormattedTime(date: string): string;
    setPosition(position: {x: number; y: number}): void;
    setCurrentTab(tab: number): void;
    startScan(): void;
}

export class WifiAnalyzerController implements IWifiAnalyzerController {
    @observable isVisible: boolean = false;

    @observable results: IWifiAnalyzerResults | null = null;

    @observable boundaries: Boundaries = {
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
        top: 0
    };

    @observable offset: Boundaries = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    };

    @observable position: {x: number; y: number} = {x: 60, y: window.innerHeight - 0.7 * window.innerHeight};
    @observable deviceCategory: DeviceCategory = DeviceCategory.desktop;
    @observable currentTab: number = 0;
    @observable isMinimized: boolean = false;

    @observable isScanning: boolean = false;
    @observable channelAnalysis: Channel[] = [];

    constructor() {
        const {environmentService, displayTabletAsDesktop} = getRootStore();

        this.deviceCategory = environmentService.deviceCategory(displayTabletAsDesktop) || DeviceCategory.desktop;
    }

    @action
    hide = () => {
        this.isVisible = false;
        this.results = null;
    };

    @action
    setIsScanning = (isScanning: boolean) => {
        this.isScanning = isScanning;
    };

    @action
    startScan = async () => {
        this.setIsScanning(true);
        if (this.results) {
            getRootStore().wifiAnalyzerService.runScanner(
                this.results.roomId,
                this.results.id,
                (channels) => {
                    this.setChannelAnalysis(channels);
                    this.setIsScanning(false);
                },
                () => this.setIsScanning(false)
            );
        }
    };

    @action
    getFormattedTime = (date: string) => getRootStore().getDateFormattedByAcc(date, 'dd/MM/yyyy hh:mm');

    @action
    show = () => {
        this.isVisible = true;

        const newLeftBar = (document.getElementsByClassName('app-left-bar') as any)[0];
        const newTopBar = (document.getElementsByClassName('app-top-bar ') as any)[0];

        this.setOffset({
            left: get(newLeftBar, 'offsetWidth', 0),
            top: get(newTopBar, 'offsetHeight', 0),
            right: 0,
            bottom: 0
        });
    };

    @action
    setResults = (results: IWifiAnalyzerResults) => {
        this.channelAnalysis = results.channelAnalysis;
        this.results = results;
    };

    @action
    setChannelAnalysis = (channelAnalysis: Channel[]) => {
        this.channelAnalysis = channelAnalysis;
    };

    @action
    setCurrentTab = (tab: number) => {
        this.currentTab = tab;
    };

    @action
    minimize = () => {
        this.isMinimized = true;

        const position = {
            y: this.boundaries.bottom - 60,
            x: 60
        };

        this.setPosition(position);
    };

    @action
    maximize = () => {
        this.isMinimized = false;

        const position = {
            y: this.boundaries.bottom - this.boundaries.bottom / 2 - 100,
            x: 60
        };

        this.setPosition(position);
    };

    @action
    setPosition = (position: {x: number; y: number}) => {
        this.position = position;
    };

    @action
    setOffset = (offset: Boundaries) => {
        this.offset = offset;
    };
}
